import { useEffect, useState, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Contract } from '@ethersproject/contracts';
import { injected } from './connectors';
import moonNFT from '../data/MoonNFT_ABI.json';
import address from '../data/address.json';

export function useMoonNFT(withSigner = false): Contract {
  const { library, account } = useWeb3React();
  return useMemo(
    () =>
      new Contract(
        address.MoonNFT,
        moonNFT,
        withSigner ? library.getSigner(account).connectUnchecked() : library,
      ),
    [withSigner, library, account],
  );
}

export function useEagerConnect() : void {
  const { activate, active } = useWeb3React();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, [activate]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}
