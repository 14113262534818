import React, { useState } from "react";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { useSnackbar } from "notistack";
import { isAddress } from "@ethersproject/address";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Link,
  Typography,
  TextField,
} from "@material-ui/core";

import { Close } from "@material-ui/icons";

import { useMoonNFT } from "@/utils/hooks";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
  },
  dialogActionsTypography: {
    textAlign: "center",
  },
}));

export default function TransferModal({ 
  open, 
  onClose,
  tokenId
} : {
  open: boolean,
  onClose: any,
  tokenId: string
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [recipient, setRecipient] = useState<string>('');

  // Web3 stuff
  const {
    active,
    error,
    activate,
    setError,
    library,
    account,
  } = useWeb3React();

  const moonNFT = useMoonNFT(library ? true : false);  

  const handleSend = () : void => {
    if(!isAddress(recipient)) {
      enqueueSnackbar("Please input a valid address", {variant: "error"});
      return;
    }

    moonNFT['safeTransferFrom(address,address,uint256)'](account, recipient, tokenId)
      .then(res => {
        enqueueSnackbar("Sending " + tokenId + " to " + recipient, {variant: "info"});
        res.wait().then(res => {
          enqueueSnackbar("Successfully sent " + tokenId +  " to " + recipient, {variant: "success"});
        })
      })
      .catch(err => {
        enqueueSnackbar(err.data.message, {variant: "error"})
        console.log(err);
        return;
      })
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="wallet-modal">
      <DialogTitle
        id="transfer-modal-title"
        className={classes.dialogTitle}
      >
        <Typography style={{ paddingRight: "6rem" }}>
          <strong>Transfer MoonNFT {tokenId}</strong>
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <TextField
          fullWidth
          variant="outlined"
          label="Recipient"
          value={recipient}
          onChange={(e) => {setRecipient(e.target.value)}}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          size="large"
          className={classes.dialogActionsTypography}
          variant="contained"
          onClick={() => {handleSend()}} 
        >
          send
        </Button>
      </DialogActions>
    </Dialog>
  );
}