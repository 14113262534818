import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther, parseEther } from "@ethersproject/units";
import { BigNumber } from "@ethersproject/bignumber";
import WalletModal from './WalletModal';
import TransferModal from "./TransferModal";
import { useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Button,
  Avatar,
  Divider,
  Link,
  Grid,
  Typography,
  TextField,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@material-ui/core';
import { useEagerConnect, useMoonNFT } from "@/utils/hooks";
import { useSnackbar } from "notistack";
import { isAddress } from "@ethersproject/address";

const IPFS_PROVIDER = "https://ipfs.zesty.market/ipfs/";

export default function App() {
  const [open, setOpen] = useState<boolean>(false);
  const [openTransfer, setOpenTransfer] = useState<boolean>(false);
  const [numToken, setNumToken] = useState<string>('1');
  const [tokenId, setTokenId] = useState<string>('');
  const [img, setImg] = useState<string>(IPFS_PROVIDER + "QmeaRyhYjVRFYoUEfdic2y9kVT9tH3TkdmHwwk8i5fLNKC");
  const [balance, setBalance] = useState<string>("0");
  const [ownedbalance, setOwnedbalance] = useState(null);
  const [tokenIdArr, setTokenIdArr] = useState<any>([]);
  const [imgArr, setImgArr] = useState<any>([]);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [timelock, setTimelock] = useState<string>('');
  const context = useWeb3React();
  const { library, chainId, account, activate, deactivate, active, error } = context;
  const moonNFT = useMoonNFT(library ? true : false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  
  useEagerConnect();

  const onClose = () => {
    setOpen(false);
  }

  const onCloseTransfer = () => {
    setOpenTransfer(false);
  }

  const handleImageClick = (tokenId: string) => {
    setOpenTransfer(true);
    setTokenId(tokenId);
  }

  const handleMint = () => {
    if (parseInt(numToken) < 1) {
        enqueueSnackbar("Need to mint at least one token", {variant: "error"});
        return;
    }
    if (parseInt(numToken) > 20) {
        enqueueSnackbar("Cannot mint more than 20 tokens at a go", {variant: "error"});
        return;
    }
    if (isNaN(parseInt(numToken))) {
        enqueueSnackbar("Please input a number", {variant: "error"});
        return;
    }

    moonNFT.mint(parseInt(numToken), { value: BigNumber.from(parseEther("0.1")).mul(numToken)})
      .then(res => {
        enqueueSnackbar("Submitted transaction to mint " + numToken + " MoonNFT", {variant: "info"});

        res.wait().then(() => {
          enqueueSnackbar("Successfully minted tokens", {variant: "success"});
        })
      })
      .catch(err => {
        enqueueSnackbar(err.data.message, {variant: "error"})
      });
  }

  const handleSetTimelock = () : void => {
    if(!isAddress(timelock)) {
      enqueueSnackbar("Please input a valid address", {variant: "error"});
      return;
    }

    moonNFT.setTimelock(timelock)
      .then(res => {
        enqueueSnackbar("Sending timelock to " + timelock, {variant: "info"});
        res.wait().then(res => {
          enqueueSnackbar("Successfully set timelock to " + timelock, {variant: "success"});
        })
      })
      .catch(err => {
        enqueueSnackbar(err.data.message, {variant: "error"})
        console.log(err);
        return;
      })
  }

  const handleWithdraw = () : void => {
    moonNFT.withdraw()
      .then(res => {
        enqueueSnackbar("Withdrawing tokens ", {variant: "info"});
        res.wait().then(res => {
          enqueueSnackbar("Successfully withdrawn ", {variant: "success"});
        })
      })
      .catch(err => {
        enqueueSnackbar(err.data.message, {variant: "error"})
        console.log(err);
        return;
      })
  }

  useEffect(() => {
    if (library) {
      library.getBalance(account).then(res => {
        setBalance(res.toString());
      });

      moonNFT.balanceOf(account).then(res => {
        let bal = res.toNumber();
        setOwnedbalance(bal);

        for (let i = 0; i < bal; i++) {
          moonNFT.tokenOfOwnerByIndex(account, i).then(res => {
            setTokenIdArr(oldArr => [...oldArr, res.toNumber()]);
          });
        }

      });

      moonNFT.owner().then(res => {
        if (res === account) {
          setIsOwner(true);
        }
      });

      moonNFT.timelock().then(res => {
        setTimelock(res);
      });
    }
  }, [library])
  
  useEffect(() => {
    if(ownedbalance) {
      if(ownedbalance == tokenIdArr.length) {
        for (let i = 0; i < tokenIdArr.length; i++) {
          moonNFT.tokenURI(tokenIdArr[i]).then(res => {
            axios.get(`${IPFS_PROVIDER}${res.substring(7)}`).then(res => {
              setImgArr(oldArr => [...oldArr, res.data]);
            });
          }).catch(err => {
            console.log(err);
          });
        }
      }
    }
  }, [tokenIdArr, library])

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Grid 
          container 
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography variant="h1" align="center">
              MoonNFT
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Avatar variant="rounded" src={img} alt="MoonNFT example" style={{
              height: 330,
              width: 330,
              marginBottom: '2rem',
            }}/>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" align="center" gutterBottom>
              Celebrate the launch of Moonriver on Kusama with a MoonNFT
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Each MoonNFT gives you one voting right to control the DAO
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              50% of funds of the mint goes to the DAO and 50% is given to the creator upon contract withdrawal
            </Typography>
            <br />
            <Typography variant="body1" align="center">
              <strong>Cost per mint: 0.1 MOVR</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {typeof account !== "string" && (
              <Button 
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpen(true);
                  if (!!library) {
                    library.provider.request({
                      jsonrpc: "2.0",
                      method: "wallet_addEthereumChain",
                      params: [
                        {
                          chainId: "0x505",
                          chainName: "Moonriver",
                          rpcUrls: ["https://rpc.moonriver.moonbeam.network"],
                          nativeCurrency: {
                            name: "MOVR",
                            symbol: "MOVR",
                            decimals: 18,
                          },
                          blockExplorerUrls: ["https://blockscout.moonriver.moonbeam.network"]
                        },
                      ],
                      id: 0,
                    });
                  } 
                  else {
                    if ((window as any)?.ethereum) {
                      let web3 = new Web3Provider((window as any)?.ethereum);
                      try { 
                         (window as any)?.ethereum.enable().then(function() {
                          web3.provider.request({
                            jsonrpc: "2.0",
                            method: "wallet_addEthereumChain",
                            params: [
                              {
                                chainId: "0x505",
                                chainName: "Moonriver",
                                rpcUrls: ["https://rpc.moonriver.moonbeam.network"],
                                nativeCurrency: {
                                  name: "MOVR",
                                  symbol: "MOVR",
                                  decimals: 18,
                                },
                                blockExplorerUrls: ["https://blockscout.moonriver.moonbeam.network"]
                              },
                            ],
                            id: 0,
                          });
                         });
                      } catch(e) {
                        console.error(e);
                      }
                    }
                    // Non-DApp Browsers
                    else {
                      alert('You have to install MetaMask !');
                    }    
                  }
                }}
              >
                Connect Wallet
              </Button>
            )}
            {typeof account === "string" && (
              <div>
                <Button variant="outlined" color="primary" size="small" fullWidth>
                  Connected: { account }
                </Button>
                <br />
                <Button variant="outlined" color="secondary" size="small" fullWidth>
                  Balance: { formatEther(balance) } MOVR
                </Button>
              </div>
            )}
            <WalletModal open={open} onClose={onClose} />
          </Grid>

          <Grid item xs={12}>
            <br />
            <br />
            <br />
            <TextField
              label="Mint Amount"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setNumToken(e.target.value);
              }}
              value={numToken}
            />
            <Button
              variant="contained"
              size="large"
              style={{
                height: '55px'
              }}
              onClick={() => {handleMint()}}
            >
              Mint
            </Button>

          </Grid>
          <Divider />
          { isOwner &&
            <div>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Owner Dashboard
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Recipient"
                  value={timelock}
                  onChange={(e) => {setTimelock(e.target.value)}}
                />              
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {handleSetTimelock()}}
                >              
                  Set Timelock
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {handleWithdraw()}}
                >              
                  Withdraw
                </Button>
              </Grid>

            </div>
          }
          <Grid item xs={12}>
            <br />
            <br />
            <Typography variant="h3">
              Your Moon NFT Collection
            </Typography>
          </Grid>
          <Grid item xs={7}>
            { mobile &&
              <ImageList rowHeight={260} cols={1}>
                {imgArr.length > 0 && imgArr.map((item, idx) => (
                  <ImageListItem key={item.name}
                    onClick={() => {
                      handleImageClick(item.name);
                    }}
                  >
                    <img src={`${IPFS_PROVIDER}${item.image.substring(7)}`} alt={item.name} 
                      style={{
                        width: "260px",
                        height: "260px"
                      }}
                    />
                    <ImageListItemBar
                      title={item.name}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            }
            { !mobile &&
              <ImageList rowHeight={260} cols={3}>
                {imgArr.length > 0 && imgArr.map((item, idx) => (
                  <ImageListItem key={item.name}
                    onClick={() => {
                      handleImageClick(item.name);
                    }}
                  >
                    <img src={`${IPFS_PROVIDER}${item.image.substring(7)}`} alt={item.name} 
                      style={{
                        width: "260px",
                        height: "260px"
                      }}
                    />
                    <ImageListItemBar
                      title={item.name}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            }
            <TransferModal open={openTransfer} onClose={onCloseTransfer} tokenId={tokenId} />
          </Grid>

          <Grid item xs={12}>
            <br />
            <br />
            <Typography variant="body1">
              <Link href="https://github.com/jseam2/moonnft">Github repo with more details</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}