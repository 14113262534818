import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import MetaMaskOnboarding from "@metamask/onboarding";
import { UserRejectedRequestError } from "@web3-react/injected-connector";
import { useSnackbar } from "notistack";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";

import { Close } from "@material-ui/icons";

import { injected } from "../utils/connectors";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
  },
  dialogActionsTypography: {
    textAlign: "center",
  },
}));

export default function WalletModal({ 
  open, 
  onClose 
} : {
  open: boolean,
  onClose: any
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [connecting, setConnecting] = useState(false);
  const [windowEth, setWindowEth] = useState(null);
  const [windowWeb3, setWindowWeb3] = useState(null);
  const [MMInstalled, setMMInstalled] = useState(false);
  const [provider, setProvider] = useState(null);

  // Web3 stuff
  const {
    active,
    error,
    activate,
    setError,
    library,
    account,
  } = useWeb3React();

  // initialize metamask onboarding
  const onboarding = useRef<MetaMaskOnboarding>();
  useEffect(() => {
    onboarding.current = new MetaMaskOnboarding();
  }, []);

  // Stop onboarding if there's an error
  useEffect(() => {
    if (active || error) {
      setConnecting(false);
      onboarding.current?.stopOnboarding();
      onClose();
    }

    if (active && !error) {
      setProvider(new Web3Provider(library.provider));
    }
  }, [active, error]);

  // Get window and check for other injected providers
  useEffect(() => {
    setWindowEth((window as any)?.eth);
    setWindowWeb3((window as any)?.web3);
    setMMInstalled(MetaMaskOnboarding.isMetaMaskInstalled());
  }, []);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="wallet-modal">
      <DialogTitle
        id="wallet-modal-title"
        className={classes.dialogTitle}
      >
        <Typography style={{ paddingRight: "6rem" }}>
          <strong>Connect to a wallet</strong>
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {MMInstalled || windowEth || windowWeb3 ? (
          <Button
            variant="outlined"
            size="large"
            fullWidth
            onClick={() => {
              setConnecting(true);
              activate(injected, undefined, true).catch((err) => {
                // ignore the error if it's a user rejected request
                if (err instanceof UserRejectedRequestError) {
                  setConnecting(false);
                  enqueueSnackbar("User rejected request", {
                    variant: "error",
                  });
                }
                else if (err instanceof UnsupportedChainIdError) {
                  setConnecting(false);
                  enqueueSnackbar("Only Moonriver Network is accepted please switch networks.", {
                    variant: "error",
                  });
                } 
                else {
                  setConnecting(false);
                  setError(error);
                }
              });
            }}
          >
            {MMInstalled ? "Connect to MetaMask" : "Connect to Wallet"}
          </Button>
        ) : (
          <Button
            variant="outlined"
            size="large"
            fullWidth
            onClick={() => {
              onboarding.current?.startOnboarding();
            }}
          >
            Install Metamask
          </Button>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Typography
          variant="body1"
          className={classes.dialogActionsTypography}
        >
          New to Ethereum?
          <br />
          <Link
            href="https://ethereum.org/en/wallets/"
            target="_blank"
            rel="noopener"
          >
            Learn more about wallets
          </Link>
        </Typography>
      </DialogActions>
    </Dialog>
  );
}