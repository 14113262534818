import React from 'react';
import { PageProps } from 'gatsby';

import { SnackbarProvider } from 'notistack';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import App from '@/components/App';

function getLibrary(provider) {
  return new Web3Provider(provider);
}

const Home: React.FC<PageProps> = () => {
  return(
    <SnackbarProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </SnackbarProvider>
  );
};

export default Home;
